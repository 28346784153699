import * as React from "react"
import { graphql } from "gatsby"

import LayoutEnglish from "../components/layoutEnglish"
import Seo from "../components/seo"

const IndexPage = ({ data }) => (
  <LayoutEnglish>
    <Seo title="Imprint / Privacy Statement" />
    <h1>Imprint / Privacy Statement</h1>
    <p>A project of Stadtmuseum Bonn and fringe ensemble
      <br/><br/>
      Concept: Stadtmuseum Bonn and fringe ensemble<br/>
      Project management: fringe ensemble
      <br/><br/>
      www.citystories-bnx.de<br/>
      mail@citystories-bnx.de<br/>
      <br/>
      The project CITY STORIES was developed within the framework of "dive in. Programme for Digital Interactions" of the Federal Cultural Foundation, funded by the Federal Government Commissioner for Culture and the Media (BKM) in the NEUSTART KULTUR programme.
      <br/><br/>
      <h2>Team</h2>
      Concept: <strong>Frank Heuel, Svenja Pauka, Annika Ley, Fehime Seven, Dr. Philipp Hoffmann, Yvonne Katzy</strong><br/>
      Management: <strong>Frank Heuel, Svenja Pauka</strong><br/>
      Assistance: <strong>Lisa August</strong><br/>
      App programming: <strong>Fehime Seven</strong><br/>
      Website: <strong>Martin Wisniowski</strong><br/>
      Design: <strong>Annika Ley</strong><br/>
      Sound: <strong>Andreas Meidinger, Lisa August</strong><br/>
      Postproduction: <strong>Andreas Meidinger</strong><br/>
      Public relations: <strong>Claudia Grönemeyer</strong><br/>
      Production management: <strong>Jennifer Merten</strong><br/>
      Interview work: <strong>Bettina Marugg, Philip Schlomm, Svenja Pauka, Claudia Grönemeyer, Andreas Meidinger, Lisa August</strong><br/>
      Speakers: <strong>Bettina Marugg, Julia Hoffstädter, Nicole Kersten, Justine Hauer, Andreas Meidinger, Philip Schlomm, David Fischer, Frank Heuel</strong><br/>
      Translation: <strong><a href="https://www.sprachgewaltig.de">www.sprachgewaltig.de</a></strong><br/>
      <br/>
      Responsible for content according to § 55 ABS.2 RStV:<br/>
      Name: fringe ensemble / Gold GbR<br/>
      Address: Goethestraße 31, 53113 Bonn, Germany<br/>
      Legal form: GbR<br/>
      Email: info@fringe-ensemble.de<br/>
      <br/>
      Information in content/exclusion of liability<br/>
      <br/>
      Stadtmuseum Bonn and fringe ensemble always aim to ensure that information provided in this Internet presence/ in this app is correct, complete and up-to-date. Nevertheless errors and ambiguities cannot be ruled out.
      <br/><br/>
      We therefore assume no guarantee that the information provided is up-to-date, correct, complete, of a high quality and available at any time nor for the absence of other technical disruptions.
      <br/><br/>
      We are not liable for losses caused by the use of the app and information or data provided or through the use of incorrect and/or incomplete information or data, insofar as it is accused of intent or gross negligence.
      <br/><br/>
      We expressly retain the right to amend, supplement, delete parts of the web pages or the entire content without a separate notification or to discontinue publication for a time or permanently.
      Information on references and links
      <br/><br/>
      This website / app also contains correspondingly marked links or references to websites belonging to a third party. Responsibility for this external content lies solely with the provider, who provides the content. We merely facilitate access for the use of this content.
      <br/><br/>
      We currently have no positive knowledge of illegal or offensive content on the linked pages of third-party providers. However, if the linked pages of other providers contain illegal or offensive content, we expressly distance ourselves from this content.
      <br/><br/>
      We are currently not aware of any unlawful or objectionable content on the pages of external providers accessed via a link. Nevertheless should the linked pages of external providers contain unlawful or objectionable content, then the City administration expressly distances itself from this content.
      <br/><br/>
      The provider of the page to which the reference is made is solely responsible for illegal, incorrect or incomplete content and in particular losses, which arise from the use or non-use of information from a third party, not the person/party merely referring to the relevant publication via links.
      <br/><br/>
      Information on copyright
      <br/><br/>
      Texts, images and audiovisual content as well as the layout and design reproduced in this service are protected by copyright and may not be used for other than purely private purposes without our prior consent or the consent of the rights holder. In particular, public reproduction or modification of the contents is not permitted. Individual contents may contain special copyright notices that must be observed (e.g. in the respective alternative text).
      <h2>Privacy Statement</h2>
      Collection and storage of personal data and the nature and purpose of their use:<br/><br/>
      When visiting the website<br/><br/>
      When you visit our website the browser used on your end device automatically sends information to the server of our website. This information is temporarily stored in a so-called log file.<br/><br/>
      The following information is collected without your intervention and stored until automated deletion:<br/><br/>
      - Date and time of access<br/>
      - Name and URL of the accessed file<br/>
      - Website from which the access was made (referrer URL)<br/>
      - browser used and, if applicable, the operating system of your computer as well as the name of your access provider<br/>
      - your Internet Protocol (IP) address<br/><br/>
      The above data is processed by us for the following purposes:<br/><br/>
      - ensuring a smooth connection setup of the website,
      - ensuring a comfortable use of our website,
      - evaluation of system security and stability as well as
      - statistical analysis to improve the website and the operation of the web server
for other administrative purposes<br/><br/>
      The legal basis for the data processing is Art. 6 para. 1 p. 1 lit. f DSGVO. Our legitimate interest follows from the purposes for data collection listed above. In no case do we use the collected data for the purpose of drawing conclusions about your person.
    </p>
  </LayoutEnglish>
)

export const query = graphql`
  query 
  { 
    allMdx {
      nodes {
        frontmatter {
          title
        }
        id
        body
        slug
      }
    }
  }
`

export default IndexPage
